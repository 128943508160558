import * as React from "react"
// import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import styled from "styled-components"
import {LabelProvider} from "../assets/js/labelDataContext"
import OrderBox from "../components/orders/OrderBox"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";

var isFirefox = false;

const OrderPage = () => (
    <Layout page="zamowienia">
        <Seo title="Zamówienie"/>

        <SmallHeroSection/>
        <DecorativeSection/>
       
        
        <section id="orders" className="orders">
            <div className="section-title">
                <h2><span>Zamówienia</span></h2>
            </div>
            <div className="container">
                <p className="text-center">
                    Nie czekaj, zamówienia możesz dokonać już teraz <strong>online</strong>!
                    {/*<h2 className="text-warning">*/}
                    {/*    Przepraszamy! Zamówienie elektroniczne sa chwilowo niedostepne.*/}
                    {/*</h2>*/}
                    {/*Prosimy o kontakt telefoniczny: <a href="tel:+48721399415">721 399 415</a>*/}
                </p>
                
                <p className="text-center">Zestawy <strong>3 </strong> posiłków dowozimy jedynie na terenie Tarnowa.</p>
                   { <p className="text-info-hol">
                    PRZERWA ŚWIĄTECZNA W DOSTAWIE CATERINGU OD 20.04.2025 DO 22.04.2025
                </p>    }
            </div>
        </section>

         <section> 
          <LabelProvider>
            <OrderBox />
          </LabelProvider>
         </section> 

    </Layout>
)

export default OrderPage
